import React from "react";
import { Link } from "gatsby";
import LinkButton from "components/link_button";
import Style from "styles/components/job_list.module.scss";
import VideoGallery from "./video_gallery";

import Interview01 from "images/recruit/interview01.jpg";
import Interview02 from "images/recruit/interview02.jpg";

const videoSrc = [
  {
    url: "https://coporate-site-video.s3.ap-northeast-1.amazonaws.com/interview01.mp4",
    poster: Interview01,
  },
  {
    url: "https://coporate-site-video.s3.ap-northeast-1.amazonaws.com/interview02.mp4",
    poster: Interview02,
  },
];

const JobList = () => {
  return (
    <section className={Style.recruitJob}>
      <div className={Style.recruitJob__inner}>
        <h2 className={Style.recruitJob__head}>Job List</h2>
        <VideoGallery videoSrc={videoSrc} />
        <ul className={Style.jobList}>
          <li className={Style.jobListItem}>
            <Link className={Style.jobListItem__link} to="/recruit/backendengineer/">
              <div className={Style.jobListItem__bg}>
                <div className={Style.jobListItem__bgInner}>
                  <div className={Style.jobListItem__txtWrapper}>
                    <div className={Style.jobListItem__txtArea}>
                      <p className={Style.jobListItem__head}>BACKEND ENGINEER</p>
                      <p className={Style.jobListItem__ja}>バックエンドエンジニア</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </li>

          <li className={Style.jobListItem}>
            <Link className={Style.jobListItem__link} to="/recruit/frontendengineer/">
              <div className={Style.jobListItem__bg}>
                <div className={Style.jobListItem__bgInner}>
                  <div className={Style.jobListItem__txtWrapper}>
                    <div className={Style.jobListItem__txtArea}>
                      <p className={Style.jobListItem__head}>FRONTEND ENGINEER</p>
                      <p className={Style.jobListItem__ja}>フロントエンドエンジニア</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </li>

          <li className={Style.jobListItem}>
            <Link className={Style.jobListItem__link} to="/recruit/projectmanager/">
              <div className={Style.jobListItem__bg}>
                <div className={Style.jobListItem__bgInner}>
                  <div className={Style.jobListItem__txtWrapper}>
                    <div className={Style.jobListItem__txtArea}>
                      <p className={Style.jobListItem__head}>PROJECT MANAGER</p>
                      <p className={Style.jobListItem__ja}>プロジェクトマネージャー</p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        </ul>
        <p className={Style.recruitJob__extraInfo}>
          ～　こちらに掲載されていない職種であっても、気軽にお問合せ・エントリー願います。　～
        </p>
        <LinkButton color={"black"} url={"/contact/"} text={"CONTACT US"} />
      </div>
    </section>
  );
};

export default JobList;
