import React from "react";
import Style from "styles/components/video_gallery.module.scss";

const VideoGallery = ({ videoSrc }) => (
  <div className={Style.container}>
    {videoSrc.map((video, idx) => {
      return (
        <video key={idx} className={Style.video} poster={video.poster} controls playsInline preload="auto">
          <source src={video.url} type="video/mp4" />
        </video>
      );
    })}
  </div>
);
export default VideoGallery;
