import React from "react";
import Layout from "components/layout";
import JobList from "components/job_list";
import Style from "styles/recruit.module.scss";
import SEO from "components/seo";
import Image from "components/image";
import { Link } from "gatsby";

const Service = () => {
  return (
    <Layout>
      <SEO title="Recruit" path="recruit" />
      <section className={Style.recruitHeading}>
        <div className={Style.recruitHeading__inner}>
          <h1 className={Style.recruitHeading__title}>RECRUIT</h1>
        </div>
      </section>
      <section className={Style.recruitIntro}>
        <div className={Style.recruitIntro__wrapper}>
          <div className={Style.recruitIntro__inner}>
            <div
              className={Style.recruitIntro__img}
              data-sal="fade"
              data-sal-duration="300"
              data-sal-delay="300"
              data-sal-easing="ease-out"
            >
              <Image filename="e-ize-engineer.jpg" />
            </div>
            <div className={Style.recruitIntro__txt}>
              <h2 className={Style.recruitIntro__title}>Engineer First</h2>
              <p>
                e-izeは、エンジニアの主体性を最大限に尊重し「何をしたいか」、「どんなエンジニアになりたいか」を大切にしています。
                社員一人ひとりが能動的に行動し、課題への挑戦を会社がサポートすることが社員と会社にとって最大の利益になると私たちは考えます。
              </p>
              <p>自立的に学び、クライアントや会社の課題に真摯に向き合える方を歓迎します。</p>
              <p>
                キャリアパスに不安がある方や技術的に不安がある方も一度ご連絡してみてください。
                最大限の努力であなたのキャリアパスに向き合います。
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={Style.internship}>
        <div className={Style.internship__inner}>
          <div
            className={Style.internship__img}
            data-sal="fade"
            data-sal-duration="300"
            data-sal-delay="300"
            data-sal-easing="ease-out"
          >
            <Image filename="people-group.jpg" />
          </div>
          <div className={Style.internship__txt}>
            <h2 className={Style.internship__head}>Internship</h2>
            <Link to="https://www.your-intern.com/award/2021/">
              <Image className={Style.internship__award__banner} filename="award2021.png" />
            </Link>
            <p>
              当社ではインターン生に対し、実績を身に着けた社会人となる準備・育成を実施しています。
              インターンに参加する学生には、積極的な実務への参加を通じて仕事への理解を深めることで、実業務に関わる際の不安や緊張をなくし、
              自信とスキルを身に着ける場を提供しています。
              <br />
              また、インターン生は自分のライフスタイルや講義のスケジュールに合わせて、自身でインターンのシフトを作成してもらうことが可能です。
            </p>
          </div>
        </div>
      </section>
      <section className={Style.recruitOverview}>
        <div className={Style.recruitOverview__inner}>
          <div className={Style.recruitOverview__head}>
            <h2>募集概要</h2>
          </div>
          <table className={Style.recruitOverviewTable}>
            <tbody className={Style.recruitOverviewTable__tbody}>
              <tr className={Style.recruitOverviewTable__item}>
                <th className={Style.recruitOverviewTable__head}>雇用形態</th>
                <td className={Style.recruitOverviewTable__content}>
                  正社員
                  <br />
                  ※入社後半年間の試用期間があります。但し、試用期間中の給与・福利厚生・休暇待遇に差異はありません。
                </td>
              </tr>
              <tr className={Style.recruitOverviewTable__item}>
                <th className={Style.recruitOverviewTable__head}>勤務時間</th>
                <td className={Style.recruitOverviewTable__content}>
                  9:30～18:30
                  <br />
                  ※取引先により就業時間が異なる場合がございます。（始業時間の変動、フレックス勤務等）
                  <br />
                  ※外部研修に参加する新入社員は研修先の開始・終了時間が適応されます。
                </td>
              </tr>
              <tr className={Style.recruitOverviewTable__item}>
                <th className={Style.recruitOverviewTable__head}>休日休暇</th>
                <td className={Style.recruitOverviewTable__content}>
                  完全週休2日制、有給休暇、祝日、GW休暇、夏季休暇、年末年始休暇、慶弔休暇、
                  バースデー休暇、結婚記念日休暇、新婚旅行休暇、産休・育児休暇、出産予定日休暇、資格試験休暇
                  <br />
                  社員からの声で制度化した休暇があります。※年間休日120日以上
                </td>
              </tr>
              <tr className={Style.recruitOverviewTable__item}>
                <th className={Style.recruitOverviewTable__head}>福利厚生</th>
                <td className={Style.recruitOverviewTable__content}>
                  給与改定年2回、交通費全額支給、確定拠出年金、各種社会保険完備、時間外手当（通常残業代）
                  出張手当、資格取得支援制度、外部研修受講費会社負担、業務貢献報奨一時金制度あり、役職手当、書籍購入制度、社員紹介制度あり（最低基本給の1か月分を支給。）
                </td>
              </tr>
              <tr className={Style.recruitOverviewTable__item}>
                <th className={Style.recruitOverviewTable__head}>勤務地</th>
                <td className={Style.recruitOverviewTable__content}>
                  イーゼの取引先又は自社
                  <br />
                  <br />
                  ・取引先勤務の場合
                  <br />
                  &emsp;東京23区内を中心とした取引先（ほぼすべての取引先業務がテレワークです。）
                  <br />
                  <br />
                  ・自社勤務の場合（ここ数年テレワーク勤務です。）
                  <br />
                  &emsp;神田オフィス （東京都千代田区神田小川町3-6-1 栄信ビル7階）
                </td>
              </tr>
            </tbody>
          </table>

          {/* <div className={Style.recruitOverviewItem}>
                <h2 className={Style.recruitOverviewItem__head}>雇用形態</h2>
                <div className={Style.recruitOverviewItem__content}>
                  正社員
                  <br />
                  ※入社後半年間の試用期間があります。但し、試用期間中の給与・福利厚生・休暇待遇に差異はありません。
                </div>
              </div>
              <div className={Style.recruitOverviewItem}>
                <h2 className={Style.recruitOverviewItem__head}>勤務時間</h2>
                <div className={Style.recruitOverviewItem__content}>
                  9:30～18:30
                  <br />
                  ※イーゼ社内勤務の場合、テレワーク勤務となります。
                  ※外部研修に参加する新入社員は研修先の開始・終了時間が適応されます。
                  ※取引先により就業時間が異なる場合がございます。（始業時間の変動、フレックス勤務等）
                </div>
              </div>
              <div className={Style.recruitOverviewItem}>
                <h2 className={Style.recruitOverviewItem__head}>休日休暇</h2>
                <div className={Style.recruitOverviewItem__content}>
                  完全週休2日制、有給休暇、祝日、GW休暇、夏季休暇、年末年始休暇、慶弔休暇、
                  バースデー休暇、結婚記念日休暇、新婚旅行休暇、産休・育児休暇、出産予定日休暇、資格試験休暇
                  <br />
                  社員からの声で制度化した休暇があります。※年間休日120日以上
                </div>
              </div>
              <div className={Style.recruitOverviewItem}>
                <h2 className={Style.recruitOverviewItem__head}>福利厚生</h2>
                <div className={Style.recruitOverviewItem__content}>
                  給与改定年2回、交通費全額支給、確定拠出年金、各種社会保険完備、時間外手当（通常残業代）
                  出張手当、資格取得支援制度、外部研修受講費会社負担
                </div>
              </div>
              <div className={Style.recruitOverviewItem}>
                <h2 className={Style.recruitOverviewItem__head}>勤務地</h2>
                <div className={Style.recruitOverviewItem__content}>
                  イーゼの取引先又は自社
                  <br />
                  ・取引先勤務の場合
                  <br />
                  東京23区内を中心とした取引先（テレワークを採用している企業が多いです。）
                  <br />
                  ・自社勤務の場合（コロナが落ち着くまではテレワーク勤務となります。）
                  <br /> 神田オフィス （東京都千代田区神田司町2-7-6 鈴木ビル2階）
                  <br />
                  ・都営新宿線「小川町駅」より徒歩3分
                  <br />
                  ・東京メトロ丸ノ内線「淡路町駅」より徒歩3分
                </div>
              </div> */}
        </div>
      </section>
      <JobList />
    </Layout>
  );
};

export default Service;
